import { BusinessProfile } from '@/app/shared/interface/account.interface';
import {
  District,
  Province,
  Ward,
} from '@/app/shared/interface/province.interface';
import { CommonService } from '@/app/shared/services/common.service';
import { Component, TransferState } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { User } from '@/app/shared/interface/user.interface';
import { AccountService } from '@/app/shared/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '@/app/shared/services/file.service';
import {
  FileResponse,
  FileTypeValue,
  FileType,
} from '@/app/shared/interface/file.interface';
import { ProvinceService } from '@/app/shared/services/province.service';
import { firstValueFrom } from 'rxjs';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { event } from 'jquery';
import { TOKEN } from '@/app/shared/services/auth.service';

@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrl: './business-profile.component.scss',
})
export class BusinessProfileComponent {
  public province: Province;
  public provinceCode: number = 0;
  public districtCode: number = 0;
  public provinces: Province[] = [];
  public districts: District[] = [];
  public wards: Ward[] = [];
  public selectedProvince: number;
  public selectedDistrict: number;
  public selectedWard: number;
  public selectedBusinessType: number;
  public fileType: string = FileTypeValue.UserDocument;
  public user: User = new User();
  public businessProfile: BusinessProfile = new BusinessProfile();
  public businessLicenses: string[] = [];
  public businessLicensesImage: string[] | null;
  public businessLicensesType: string;
  public businessCertifications: string[] = [];
  public businessCertificationsImage: string[] | null;
  public businessCertificationsType: string;
  public distributionCertifications: string[] = [];
  public distributionCertificationsImage: string[] | null;
  public distributionCertificationsType: string;
  public allowedTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
  ];
  public fileSizeLimit = 10;
  public formOfBusinesses = [
    {
      code: 0,
      name: 'Nhà thuốc',
    },
    {
      code: 1,
      name: 'Quầy thuốc',
    },
    {
      code: 2,
      name: 'Tủ thuốc trạm y tế xã',
    },
    {
      code: 3,
      name: 'Cơ sở bán buôn',
    },
    {
      code: 4,
      name: 'Bệnh viện',
    },
    {
      code: 5,
      name: 'Cơ sở khám chữa bệnh khác',
    },
    {
      code: 6,
      name: 'Khác',
    },
  ];
  form: FormGroup;

  select2Provinces: any;
  select2Districts: any;
  select2Wards: any;

  loadingDistricts: boolean = false;
  loadingWards: boolean = false;

  constructor(
    private toastService: ToastrService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private fileService: FileService,
    private provinceService: ProvinceService,
    private cookieService: SsrCookieService,
    private transferState: TransferState
  ) {
    this.form = this.formBuilder.group({
      businessType: new FormControl('', [Validators.required]),
      businessName: new FormControl('', [Validators.required]),
      taxCode: new FormControl(''),
      province: new FormControl('', [Validators.required]),
      district: new FormControl('', [Validators.required]),
      ward: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      businessLicenses: new FormControl(null),
      businessCertifications: new FormControl(null),
      distributionCertifications: new FormControl(null),
    });
  }

  ngOnInit() {
    this.provinceService
      .getProvinceInfoByRegion()
      .subscribe((res: any) => {
        if (res) {
          this.provinces = res;
          this.select2Provinces = this.provinces.map(
            (province: Province) =>
              new Object({
                value: province.code,
                label: province.name,
              })
          );
          this.getBusinessProfile();
        }
      });
  }

  getBusinessProfile() {
    //get business profile
    this.accountService.getUserData.subscribe(async (res) => {
      if (res && res.businessName) {
        this.user = res;
        this.form.patchValue({
          businessType: res.businessType,
          businessName: res.businessName,
          taxCode: res.taxNumber,
          province: res.address?.province.code,
          district: res.address?.province.districts[0]?.code,
          ward: res.address?.province.districts[0]?.wards[0]?.code,
          address: res.address?.address,
        });

        this.selectedBusinessType = res.businessType!;
        this.selectedProvince = res.address?.province.code!;
        //district

        const resDistrict = await firstValueFrom(
          this.provinceService.getProvinceInfoByDepth(
            this.selectedProvince,
            this.districtCode
          )
        );
        if (resDistrict) {
          this.districts = resDistrict[0].districts;
          this.select2Districts = this.districts.map(
            (district: District) =>
              new Object({
                value: district.code,
                label: district.name,
              })
          );
          this.selectedDistrict = res.address?.province.districts[0].code!;
          //ward

          const resWard = await firstValueFrom(
            this.provinceService.getProvinceInfoByDepth(
              this.selectedProvince,
              this.selectedDistrict
            )
          );

          if (resWard) {
            this.wards = resWard[0].districts[0].wards;
            this.select2Wards = this.wards.map(
              (ward: Ward) =>
                new Object({
                  value: ward.code,
                  label: ward.name,
                })
            );
            this.selectedWard =
              res.address?.province.districts[0].wards[0].code!;
          }
        }

        if (
          res.businessRegistrationCert &&
          res.businessRegistrationCert.length > 0
        ) {
          let cert = res.businessRegistrationCert[0];
          if (cert.includes("giathuochapu.com")) { // Hotfix: ECRMS-735 -> can be remove after migrate cert
            this.businessLicensesImage = [cert];
          } else {
            this.fileService
              .getSingedUrl(
                FileType.UserDocument,
                res.businessRegistrationCert[0]
                  .substring(res.businessRegistrationCert[0].lastIndexOf('/'))
                  .slice(1)
              )
              .subscribe({
                next: (res: any) => {
                  this.businessLicensesImage = res;
                },
              });
          }
        }
        if (
          res.businessRegistrationCert2 &&
          res.businessRegistrationCert2.length > 0
        ) {
          let cert = res.businessRegistrationCert2[0];
          if (cert.includes("giathuochapu.com")) { // Hotfix: ECRMS-735 -> can be remove after migrate cert
            this.businessCertificationsImage = [cert];
          } else {
            this.fileService
              .getSingedUrl(
                FileType.UserDocument,
                res.businessRegistrationCert2[0]
                  .substring(res.businessRegistrationCert2[0].lastIndexOf('/'))
                  .slice(1)
              )
              .subscribe({
                next: (res: any) => {
                  this.businessCertificationsImage = res;
                },
              });
          }
        }
        if (
          res.pharmaceuticalStandard &&
          res.pharmaceuticalStandard.length > 0
        ) {
          let cert = res.pharmaceuticalStandard[0];
          if (cert.includes("giathuochapu.com")) { // Hotfix: ECRMS-735 -> can be remove after migrate cert
            this.distributionCertificationsImage = [cert];
          } else {
            this.fileService
              .getSingedUrl(
                FileType.UserDocument,
                res.pharmaceuticalStandard[0]
                  .substring(res.pharmaceuticalStandard[0].lastIndexOf('/'))
                  .slice(1)
              )
              .subscribe({
                next: (res: any) => {
                  this.distributionCertificationsImage = res;
                },
              });
          }
        }
      }
    });
  }

  remove(index: number, type: string) {
    if (type == 'businessLicenses') {
      this.businessLicensesImage = null;
    }
    if (type == 'businessCertifications') {
      this.businessCertificationsImage = null;
    }
    if (type == 'distributionCertifications') {
      this.distributionCertificationsImage = null;
    }
  }

  getFileInfo(files: string[]) {
    let content = files[0].substring(files[0].lastIndexOf('/')).slice(1);
    let type = files[0].substring(files[0].lastIndexOf('.')).slice(1);
    if (type.includes('pdf')) {
      type = 'application/' + type;
    } else {
      type = 'image/' + type;
    }
    let data = new Blob([content], {
      type: type + files[0].substring(files[0].lastIndexOf('.')).slice(1),
    });
    let arrayOfBlob = new Array<Blob>();
    arrayOfBlob.push(data);
    let applicationZip = new File(arrayOfBlob, content, {
      type: type,
    });
    console.log(applicationZip);
    return applicationZip;
  }

  onSelect(event: NgxDropzoneChangeEvent, type: number) {
    switch (type) {
      case 0:
        if (this.checkFileType(event)) {
          this.setFiles(this.businessProfile.businessLicenses, event);
        }

        break;
      case 1:
        if (this.checkFileType(event)) {
          this.setFiles(this.businessProfile.businessCertifications, event);
        }
        break;
      case 2:
        if (this.checkFileType(event)) {
          this.setFiles(this.businessProfile.distributionCertifications, event);
        }
        break;
    }
  }
  checkFileType(event: any) {
    const file = event.addedFiles[0];
    if (!this.allowedTypes.includes(file.type)) {
      this.toastService.error('Chỉ được up file ảnh hoặc pdf', '', {
        positionClass: 'toast-top-right',
      });
      return false;
    }
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > this.fileSizeLimit) {
      this.toastService.error('File không được quá 10MB', '', {
        positionClass: 'toast-top-right',
      });
      return false;
    }
    return true;
  }

  onRemove(event: File, type: number) {
    switch (type) {
      case 0:
        this.businessProfile.businessLicenses.splice(
          this.businessProfile.businessLicenses.indexOf(event),
          1
        );
        break;
      case 1:
        this.businessProfile.businessCertifications.splice(
          this.businessProfile.businessCertifications.indexOf(event),
          1
        );
        break;
      case 2:
        this.businessProfile.distributionCertifications.splice(
          this.businessProfile.distributionCertifications.indexOf(event),
          1
        );
        break;
    }
  }

  setFiles(files: File[], event: any) {
    if (files.length + event.addedFiles.length < 2) {
      files.push(...event.addedFiles);
    } else {
      this.toastService.error(`Bạn chỉ được up 1 file.`, '', {
        positionClass: 'toast-top-right',
      });
    }
  }

  async loadDistrictsByProvince($event: any) {
    //filter district by province
    if ($event.value && !this.loadingDistricts && this.selectedProvince != $event.value) {
      this.loadingDistricts = true;

      const res = await firstValueFrom(
        this.provinceService.getProvinceInfoByDepth(
          $event.value,
          this.districtCode
        )
      );

      if (res) {
        this.districts = res[0].districts;
        this.select2Districts = this.districts.map(
          (district: District) =>
            new Object({
              value: district.code,
              label: district.name,
            })
        );
        this.selectedProvince = $event.value;
        this.selectedDistrict = 0;
        this.selectedWard = 0;
        this.form.get('district')?.reset();
        this.form.get('ward')?.reset();
        this.select2Wards = [];
        this.wards = [];
        this.loadingDistricts = false;
      }
    }
  }

  async loadWardsByDistrict($event: any) {
    if ($event.value && !this.loadingWards && this.selectedDistrict != $event.value) {
      this.loadingWards = true;

      const res = await firstValueFrom(
        this.provinceService.getProvinceInfoByDepth(
          this.selectedProvince,
          $event.value
        )
      );

      if (res) {
        this.wards = res[0].districts[0].wards;
        this.select2Wards = this.wards.map(
          (ward: Ward) =>
            new Object({
              value: ward.code,
              label: ward.name,
            })
        );
        this.form.get('ward')?.setValue(this.selectedWard);
        this.loadingWards = false;
      }
    }
  }

  selectWard(event: any) { }

  async loadUrlFileUpload(files: File[], type: Number) {
    if (files.length > 0) {
      // const token = this.cookieService.get('token');
      const token = this.transferState.get(TOKEN, '');
      let jwtToken = this.commonService.parseJwt(token!);
      // console.log(jwtToken);
      // console.log(files);
      // console.log(this.fileType);
      await this.fileService
        .uploadFileUser(files, this.fileType, jwtToken.UserName)
        .toPromise()
        .then((res: any) => {
          console.log(res);
          if (res) {
            if (type == 1) {
              this.businessLicenses.push(res.fileUrl);
            }
            if (type == 2) {
              this.businessCertifications.push(res.fileUrl);
            }
            if (type == 3) {
              this.distributionCertifications.push(res.fileUrl);
            }
          }
        });
    }
  }
  async save() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.user.businessType = Number(this.form.value.businessType);
    this.user.businessName = this.form.value.businessName;
    this.user.taxNumber = this.form.value.taxCode;

    var res = await firstValueFrom(
      this.provinceService.getProvinceInfoByDepth(
        this.form.get('province')?.value,
        this.form.get('district')?.value
      )
    );
    if (res) {
      this.province = res[0];
      this.province.districts = this.province.districts.filter(
        (a) => a.code == this.form.get('district')?.value
      );
      this.province.districts[0].wards =
        this.province.districts[0].wards.filter(
          (a) => a.code == this.form.get('ward')?.value
        );
    }
    this.user.address!.province = this.province;
    this.user.address!.address = this.form.value.address;
    this.user.taxNumber = this.form.value.taxCode;
    if (this.businessProfile.businessLicenses.length > 0) {
      await this.loadUrlFileUpload(this.businessProfile.businessLicenses, 1);
      if (this.businessLicenses != null && this.businessLicenses.length > 0) {
        this.user.businessRegistrationCert = this.businessLicenses;
      }
    } else if (this.businessLicensesImage == null) {
      this.user.businessRegistrationCert = [];
    }
    if (this.businessProfile.businessCertifications.length > 0) {
      await this.loadUrlFileUpload(
        this.businessProfile.businessCertifications,
        2
      );
      if (this.businessCertifications != null) {
        this.user.businessRegistrationCert2 = this.businessCertifications;
      }
    } else if (this.businessCertificationsImage == null) {
      this.user.businessRegistrationCert2 = [];
    }
    if (this.businessProfile.distributionCertifications.length > 0) {
      await this.loadUrlFileUpload(
        this.businessProfile.distributionCertifications,
        3
      );
      if (this.distributionCertifications != null) {
        this.user.pharmaceuticalStandard = this.distributionCertifications;
      }
    } else if (this.distributionCertificationsImage == null) {
      this.user.pharmaceuticalStandard = [];
    }

    this.user.isReviewRequested = true;

    this.accountService
      .updateUser(JSON.stringify(this.user))
      .subscribe((res) => {
        if (res) {
          this.toastService.success('Sửa hồ sơ kinh doanh thành công', '', {
            positionClass: 'toast-top-right',
          });
          this.accountService.setUserData(this.user);
          this.getBusinessProfile();
        } else {
          this.toastService.error('Sửa hồ sơ kinh doanh thất bại', '', {
            positionClass: 'toast-top-right',
          });
        }
      });
  }
}
